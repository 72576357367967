// src/components/Newsletter/Newsletter.js
import React, { useState } from 'react';
import './Newsletter.css';

const Newsletter = () => {
  const [email, setEmail] = useState('');
  const [gender, setGender] = useState('');

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleGenderChange = (e) => {
    setGender(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Invia l'email e il genere al server o gestisci come preferisci
    console.log('Email:', email);
    console.log('Gender:', gender);
    // Aggiungi qui la logica per inviare i dati al server o eseguire altre azioni
  };

  return (
    <div className="newsletter-container">
      <h2>Iscriviti alla nostra Newsletter</h2>
      <p>Iscriviti alla newsletter per restare sempre aggiornato sulle novità e promozioni.</p>
      <form onSubmit={handleSubmit}>
        <label>
          Email:
          <input type="email" value={email} onChange={handleEmailChange} required />
        </label>

        <label className="gender-label">
          Sesso:
          <select value={gender} onChange={handleGenderChange} required>
            <option value="" disabled>Seleziona</option>
            <option value="male">Maschio</option>
            <option value="female">Femmina</option>
            <option value="other">Altro</option>
          </select>
        </label>

        <button type="submit">Iscriviti</button>
      </form>
    </div>
  );
};

export default Newsletter;
