// MegaMenu.js
import React from 'react';
import './MegaMenu.css'; // Importa gli stili per il MegaMenu

const MegaMenu = ({ isVisible }) => {
  const megaMenuClass = isVisible ? 'mega-menu visible' : 'mega-menu';

  return (
    <div className={megaMenuClass}>
      {/* Contenuto del MegaMenu */}
      <div className="mega-menu-content">
        <div className="mega-menu-item">
          <a href="#">Link 1</a>
        </div>
        <div className="mega-menu-item">
          <a href="#">Link 2</a>
        </div>
        {/* Aggiungi altri elementi del MegaMenu secondo necessità */}
      </div>
    </div>
  );
};

export default MegaMenu;
