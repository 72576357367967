// src/Components/ProductCarousel1/ProductCarousel1.js

import React from 'react';
import Slider from 'react-slick';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import products from '../../data/Products/Products';
import ProductItem from '../ProductItem/ProductItem';
import './ProductCarousel1.css';

const ProductCarousel = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 900,
        slidesToShow: 6, // Imposta il numero di prodotti da mostrare per riga
        slidesToScroll: 6, // Imposta il numero di prodotti da scorrere
        autoplay: true,
        autoplaySpeed: 2000,
        swipeToSlide: true, // Permetti lo scorrimento con il mouse
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 768, // Imposta una media query per dispositivi con larghezza massima di 768px
                settings: {
                    slidesToShow: 2, // Mostra solo un prodotto per riga su dispositivi mobili
                    slidesToScroll: 2, // Scorri un prodotto alla volta su dispositivi mobili
                },
            },
        ],
    };

    return (
        <div>
            <h2>Tendenze Donna</h2>
            <Slider {...settings}>
                {products.map((product) => (
                    <div key={product.id}>
                        <ProductItem product={product} />
                    </div>
                ))}
            </Slider>
        </div>
    );
};

const NextArrow = ({ onClick }) => (
    <div className="arrow next" onClick={onClick}>
        <FontAwesomeIcon icon={faChevronRight} />
    </div>
);

const PrevArrow = ({ onClick }) => (
    <div className="arrow prev" onClick={onClick}>
        <FontAwesomeIcon icon={faChevronLeft} />
    </div>
);

export default ProductCarousel;
