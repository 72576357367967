// FooterBottom.js
import React from 'react';
import './FooterBottom.css'; 

const FooterBottom = () => {
  return (
    <div className="footer-bottom-container">
      <div className="footer-bottom">
        <p>&copy; 2023 Styleoutfit. Tutti i diritti riservati.</p>
      </div>
    </div>
  );
};

export default FooterBottom;
