
// src/Components/Auth/Login/Login.js
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, Link } from 'react-router-dom'; // Importa useNavigate e Link da react-router-dom
import Register from '../Register/Register'; // Importa il componente Register
import PasswordRecover from '../../../pages/PasswordRecover/PasswordRecover'; // Importa il componente PasswordRecover
import './Login.css';

const Login = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const [showRegister, setShowRegister] = useState(false); // Stato per mostrare/nascondere il componente Register
  const [showPasswordRecover, setShowPasswordRecover] = useState(false); // Stato per mostrare/nascondere il componente PasswordRecover
  const navigate = useNavigate(); // Usa useNavigate per la navigazione

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Qui puoi aggiungere la logica di accesso, ad esempio una chiamata API
    console.log(formData);

    // Dopo aver effettuato l'accesso, puoi reindirizzare l'utente alla pagina del suo account
    navigate('/account');
  };

  const handleShowRegister = () => {
    // Mostra il componente Register quando l'utente fa clic sul link "Registrati qui"
    setShowRegister(true);
  };

  const handleShowPasswordRecover = () => {
    // Mostra il componente PasswordRecover quando l'utente fa clic sul link "Password dimenticata?"
    setShowPasswordRecover(true);
  };

  return (
    <div className="login-container">
      <Helmet>
        <title>Accesso - Titolo SEO Personalizzato</title>
      </Helmet>

      <h2>Login</h2>
      <hr /> {/* Riga orizzontale */}
      <p>È bello rivederti!</p>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Email</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Password</label>
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit">Accedi</button>
      </form>

      {/* Aggiungi un link per mostrare il componente PasswordRecover */}
      <p><Link to="/password-recover" onClick={handleShowPasswordRecover}>Password dimenticata?</Link></p>

      {/* Mostra il componente Register quando l'utente fa clic sul link */}
      <p>Non hai ancora un account? <Link to="/register">Registrati qui</Link></p>

      {/* Mostra il componente Register quando showRegister è true */}
      {showRegister && <Register />}

      {/* Mostra il componente PasswordRecover quando showPasswordRecover è true */}
      {showPasswordRecover && <PasswordRecover />}
    </div>
  );
};

export default Login;




