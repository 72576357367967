// src/Components/Filters/CategoryFilter/CategoryFilter.js
import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './CategoryFilter.css';

const CategoryFilter = ({ categories, selectedCategory, onCategoryChange }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleCategoryChange = (value) => {
    onCategoryChange(value);

    // Aggiorna l'URL quando la categoria cambia
    const searchParams = new URLSearchParams(location.search);
    if (value) {
      searchParams.set('category', value);
    } else {
      searchParams.delete('category');
    }

    // Utilizza il metodo navigate per aggiornare l'URL senza ricaricare la pagina
    navigate({ search: searchParams.toString() });
  };

  return (
    <div className="filter">
      <h5>Categoria Prodotti</h5>
      {/* Aggiungi bottoni per passare tra le categorie */}
      <div className="category-buttons">
        {categories.map((category) => (
          <Link key={category.id} to={`/${category.slug}`} className={selectedCategory === category.id ? 'active' : ''}>
            <button onClick={() => handleCategoryChange(category.id)}>
              {category.name}
            </button>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default CategoryFilter;




