// src/Components/ProductItem/ProductItem.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import SingleProduct from '../SingleProduct/SingleProduct'; // Importa il componente SingleProduct
import './ProductItem.css';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS

const ProductItem = ({ product }) => {
  return (
    <div className="product-card">
      <div className="product-image-container">
        <SingleProduct product={product} /> {/* Usa il componente SingleProduct all'interno di ProductItem */}
        <div className="discount-label"> -{product.discount}%</div>
        <div className="wishlist-icon">
          <FontAwesomeIcon icon={faHeart} style={{ color: '#f00074' }} />
        </div>
      </div>
      <h3 className="product-name">{product.name}</h3>
      <span className="product-price">
        <span className="original-price">${product.price}</span>
        <span className="discounted-price"> ${calculateDiscountedPrice(product.price, product.discount)}</span>
      </span>
    </div>
  );
};

// Funzione di supporto per calcolare il prezzo scontato
function calculateDiscountedPrice(originalPrice, discountPercentage) {
  const discount = (discountPercentage / 100) * parseFloat(originalPrice);
  const discountedPrice = parseFloat(originalPrice) - discount;
  return discountedPrice.toFixed(2);
}

export default ProductItem;
