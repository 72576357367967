// src/Components/Account/Account.js
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; // Importa la libreria axios
import './Account.css';
import Wishlist from '../Wishlist/Wishlist'; // Importa il componente Wishlist

const Account = ({ isAuthenticated }) => {
  const navigate = useNavigate();
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [selectedOption, setSelectedOption] = useState('Informazioni'); // Aggiungi lo stato per la voce selezionata
  const [userData, setUserData] = useState(null); // Stato per immagazzinare i dati dell'utente

  useEffect(() => {
    // Effettua una richiesta API per ottenere i dati dell'utente quando il componente si monta o quando l'utente è autenticato
    if (isAuthenticated) {
      axios.get('/api/user') // Sostituisci con l'URL corretto per recuperare i dati dell'utente
        .then((response) => {
          setUserData(response.data); // Imposta i dati dell'utente nello stato
        })
        .catch((error) => {
          console.error('Errore nel recupero dei dati dell\'utente', error);
        });
    }
  }, [isAuthenticated]);

  const handleChangePassword = () => {
    // Aggiungi qui la logica per cambiare la password
    if (newPassword === confirmPassword) {
      // Esempio di logica: Se la nuova password e la conferma corrispondono, effettua il cambio
      // Puoi sostituire questa logica con una chiamata API al tuo server
      setPasswordChanged(true);
    } else {
      // Gestisci l'errore se le password non corrispondono
      alert("Le password non corrispondono. Riprova.");
    }
  };

  // Funzione per gestire la selezione delle voci di navigazione
  const handleNavigationSelect = (option) => {
    setSelectedOption(option);
  };

  // Verifica se l'utente è autenticato
  if (!isAuthenticated) {
    // Se l'utente non è autenticato, reindirizzalo alla pagina di registrazione
    navigate('/register');
    return null; // Puoi anche rendere un componente vuoto o un messaggio qui
  }

  return (
    <div className="account-container">
      {/* Utilizza Helmet per impostare il titolo SEO */}
      <Helmet>
        <title>Il Mio Account - Titolo SEO Personalizzato</title>
        {/* Altri metadati Helmet se necessario */}
      </Helmet>

      <div className="account-navigation">
        {/* Barra laterale con voci di navigazione */}
        <ul>
          <li
            className={selectedOption === 'Informazioni' ? 'active' : ''}
            onClick={() => handleNavigationSelect('Informazioni')}
          >
            Informazioni
          </li>
          <li
            className={selectedOption === 'Password' ? 'active' : ''}
            onClick={() => handleNavigationSelect('Password')}
          >
            Password
          </li>
          <li
            className={selectedOption === 'Wishlist' ? 'active' : ''}
            onClick={() => handleNavigationSelect('Wishlist')}
          >
            Wishlist
          </li>
        </ul>
      </div>

      <div className="account-content">
        {/* Contenuto basato sulla voce selezionata */}
        {selectedOption === 'Informazioni' && (
          <div>
            <h2>Informazioni</h2>
            {userData ? (
              <div>
                <p>Nome: {userData.nome}</p>
                <p>Cognome: {userData.cognome}</p>
                <p>Stile: {userData.stile}</p>
                <p>Colore: {userData.colore}</p>
              </div>
            ) : (
              <p>Caricamento delle informazioni...</p>
            )}
          </div>
        )}
        {selectedOption === 'Password' && (
          <div>
            <h2>Password</h2>
            {/* Form per il cambio della password */}
          </div>
        )}
        {selectedOption === 'Wishlist' && <Wishlist />}
      </div>

      {/* Altri elementi del tuo account */}
    </div>
  );
};

export default Account;




