// src/pages/CategoryPage/CategoryPage.js
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import ProductList from '../../Components/ProductList/ProductList';
import Breadcrumb from '../../Components/Breadcrumb/Breadcrumb';
import Filters from '../../Components/Filters/Filters';
import categories from '../../data/Categories/Categories';
import { getCategoryHierarchy } from '../../utils/categoryUtils';
import products from '../../data/Products/Products';
import './CategoryPage.css';

const CategoryPage = () => {
  const { category: categoryName } = useParams();
  const categorySlug = categoryName.toLowerCase();

  const currentCategory = categories.find(category => category.slug === categorySlug);

  // Inizializza appliedFilters come un oggetto vuoto
  const [appliedFilters, setAppliedFilters] = useState({});

  if (currentCategory) {
    const categoryHierarchy = getCategoryHierarchy(currentCategory, categories);

    // Calcola i filtri solo se currentCategory esiste
    const filteredProducts = products.filter(product =>
      product.categories.includes(currentCategory.id) ||
      categories.some(category =>
        category.parent === currentCategory.id && product.categories.includes(category.id)
      )
    );

    const breadcrumbItems = categoryHierarchy.map(category => ({
      name: category.name,
      path: `/${category.slug}`,
    }));

    return (
      <div className="category-page">
        <Breadcrumb items={breadcrumbItems} />
        <div className="category-content">
          <Filters
            categories={categories}
            filters={appliedFilters}
            onChange={setAppliedFilters}
          />
          <ProductList products={filteredProducts} />
        </div>
      </div>
    );
  } else {
    // Gestione del caso in cui la categoria non esiste
    return <div>Categoria non trovata.</div>;
  }
};

export default CategoryPage;
