// src/pages/PasswordRecover/PasswordRecover.js
import React from 'react';
import { Helmet } from 'react-helmet';
import ForgotPassword from '../../Components/Auth/ForgotPassword/ForgotPassword'; // Assicurati di fornire il percorso corretto al tuo componente ForgotPassword
import './PasswordRecover.css'; // Assicurati di avere un file CSS appropriato per questa pagina se necessario

const PasswordRecover = () => {
  return (
    <div className="password-recover-container">
      <Helmet>
        <title>Password Dimenticata - Titolo SEO Personalizzato</title>
      </Helmet>

      <ForgotPassword />
    </div>
  );
};

export default PasswordRecover;

