// src/Components/HeaderDesktop/HeaderDesktop.js
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import NavBarDesktop from '../NavBarDesktop/NavBarDesktop';
import SearchBarDesktop from '../SearchBarDesktop/SearchBarDesktop';
import Logo from '../Logo/Logo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import './HeaderDesktop.css';

function HeaderDesktop() {
  const [headerWidth, setHeaderWidth] = useState(0);

  useEffect(() => {
    // Calcola la larghezza dell'header quando il componente è montato
    const headerElement = document.querySelector('.header-desktop');
    if (headerElement) {
      const width = headerElement.offsetWidth;
      setHeaderWidth(width);
    }
  }, []);

  return (
    <div className="header-desktop">
      <div className="header-top">
        <div className="header-logo">
          <Logo />
        </div>
        <div className="header-center">
          <SearchBarDesktop />
        </div>
        <Link to="/login" className="header-right">
          <div className="account-icon">
            <FontAwesomeIcon icon={faUser} />
            <span className="account-text">Accedi</span>
          </div>
        </Link>
      </div>
      <div className="header-bottom">
        <NavBarDesktop headerWidth={headerWidth} />
      </div>
    </div>
  );
}

export default HeaderDesktop;


