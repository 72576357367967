// src/pages/Wishlist/Wishlist.js
import React from 'react';
import './Wishlist.css';

const Wishlist = () => {
  return (
    <div className="wishlist-container">
      <h2>La Tua Wishlist</h2>
      <div className="wishlist-items">
        {/* Qui puoi elencare gli articoli nella wishlist */}
        {/* Esempio di un articolo */}
        <div className="wishlist-item">
          <img
            src="url-dell-immagine"
            alt="Nome dell'articolo"
            className="wishlist-item-image"
          />
          <div className="wishlist-item-details">
            <h3>Nome dell'articolo</h3>
            <p>Descrizione dell'articolo</p>
            <button className="wishlist-remove-button">Rimuovi</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Wishlist;
