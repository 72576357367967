// scr/Components/Logo/Logo.js
import React from 'react';
import { Link } from 'react-router-dom';
import './Logo.css';

const Logo = () => {
  return (
    <Link to="/" className="logo-link">
      <div className="logo-container">
        <img src="/logo.png" alt="Il Tuo Logo" className="logo-image" />
        {/* Aggiungi altri elementi o stili se necessario */}
      </div>
    </Link>
  );
};

export default Logo;