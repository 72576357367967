// src/utils/categoryUtils.js
const getCategoryHierarchy = (category, allCategories, hierarchy = []) => {
    if (category) {
      hierarchy.unshift(category);
      if (category.parent) {
        const parentCategory = allCategories.find(c => c.id === category.parent);
        getCategoryHierarchy(parentCategory, allCategories, hierarchy);
      }
    }
    return hierarchy;
  };
  
  export { getCategoryHierarchy };
  