// pages/CategoryPage/SubcategoryPage.js
import React from 'react';
import { useParams } from 'react-router-dom';
import ProductList from '../../Components/ProductList/ProductList';
import products from '../../data/Products/Products';

const SubcategoryPage = () => {
  const { subcategorySlug } = useParams();
  const subcategorySlugLower = subcategorySlug.toLowerCase();

  // Filtra i prodotti in base alla sottocategoria corrente
  const filteredProducts = products.filter(product =>
    product.categories.some(category => category.slug === subcategorySlugLower)
  );

  return (
    <div className="product-container">
      <h2>{subcategorySlug}</h2>
      <ProductList products={filteredProducts} />
    </div>
  );
};

export default SubcategoryPage;
