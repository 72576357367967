// CategoryList.js

const categories = {
    Donna: {
      id: 1,
      name: 'Donna',
      image: 'slider1.jpg',
      subcategories: [
        {
          id: 7,
          name: 'Scarpe',
          image: 'slider1.jpg',
        },
        {
          id: 8,
          name: 'Abbigliamento',
          image: 'slider1.jpg',
        },
      ],
    },
    Uomo: {
      id: 2,
      name: 'Uomo',
      image: 'slider1.jpg',
      subcategories: [
        {
          id: 9,
          name: 'Scarpe',
          image: 'slider1.jpg',
        },
        {
          id: 10,
          name: 'Abbigliamento',
          image: 'slider1.jpg',
        },
      ],
    },
    BambineRagazze: {
      id: 3,
      name: 'Bambine e Ragazze',
      image: 'slider2.jpg',
      subcategories: [
        {
          id: 11,
          name: 'Scarpe',
          image: 'slider2.jpg',
        },
        {
          id: 12,
          name: 'Abbigliamento',
          image: 'slider2.jpg',
        },
      ],
    },
    BambiniRagazzi: {
      id: 4,
      name: 'Bambini e Ragazzi',
      image: 'slider3.jpg',
      subcategories: [
        {
          id: 13,
          name: 'Scarpe',
          image: 'slider3.jpg',
        },
        {
          id: 14,
          name: 'Abbigliamento',
          image: 'slider3.jpg',
        },
      ],
    },
    // ... altre categorie con le relative sottocategorie
  };
  
  export default categories;
  