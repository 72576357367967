// src/Components/ProductList/ProductList.js
import React from 'react';
import ProductItem from '../ProductItem/ProductItem';
import './ProductList.css';

const ProductList = ({ products }) => {
  return (
    <div className="product-list-container">
      {products.map((product, index) => (
        <div key={product.id} className="product-list-item">
          <ProductItem product={product} />
          {(index + 1) % 4 === 0 && <div className="clearfix" />}
          {/* Aggiunto un div clearfix ogni 4 elementi per iniziare una nuova riga */}
        </div>
      ))}
    </div>
  );
};

export default ProductList;
