// src/Components/Auth/ForgotPassword/ForgotPassword.js
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import './ForgotPassword.css';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [emailSent, setEmailSent] = useState(false); // Stato per gestire la visualizzazione del messaggio

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Qui puoi aggiungere la logica per inviare una richiesta di reset della password
    console.log('Email inserita:', email);

    // Dopo aver inviato l'email, imposta lo stato emailSent su true
    setEmailSent(true);
  };

  return (
    <div className="forgot-password-container">
      <Helmet>
        <title>{emailSent ? 'E-mail inviata con successo' : 'Password Dimenticata - Titolo SEO Personalizzato'}</title>
      </Helmet>

      {emailSent ? (
        <h2>E-mail inviata con successo</h2>
      ) : (
        <h2>Password Dimenticata</h2>
      )}

      {emailSent ? (
        <p>Ti abbiamo inviato una email</p>
      ) : (
        <>
          <p>Inserisci il tuo indirizzo email per reimpostare la password.</p>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>Email</label>
              <input
                type="email"
                name="email"
                value={email}
                onChange={handleChange}
                required
              />
            </div>
            <button type="submit">Invia Email di Reset</button>
          </form>
        </>
      )}
    </div>
  );
};

export default ForgotPassword;
