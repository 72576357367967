// src/pages/Home/Home.js
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import './Home.css';
import CoverBoxImage1 from '../../Components/CoverBoxImage1/CoverBoxImage1'; // Corrected import
import CoverBoxCategory1 from '../../Components/CoverBoxCategory1/CoverBoxCategory1';
import CoverBoxBrand1 from '../../Components/CoverBoxBrand/CoverBoxBrand1';
import categories from '../../Components/Category/Category';
import ProductCarousel from '../../Components/ProductCarousel/ProductCarousel1';
import CoverBoxImage2 from '../../Components/CoverBoxImage2/CoverBoxImage2'; // Corrected import
import Newsletter from '../../Components/Newsletter/Newsletter';

const Home = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const totalImages = 3;

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % totalImages);
    }, 5000);

    return () => clearInterval(intervalId);
  }, [currentIndex, totalImages]);

  const visibleCategories = Object.values(categories).slice(0, 4);

  // Dynamic title for the Home page
  const pageTitle = 'Benvenuto nella nostra homepage!';

  // Custom SEO title
  const seoTitle = 'Il tuo titolo SEO personalizzato per la pagina Home';

  return (
    <div className="home-container">
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content="Una breve descrizione della tua homepage per i motori di ricerca." />
        {/* Add the SEO title to the meta description */}
        <meta name="og:title" content={seoTitle} />
      </Helmet>

      <h1 className="hide-visual">{pageTitle}</h1>

      <CoverBoxImage1 categories={categories} currentIndex={currentIndex} />

      {/* Aggiungi CoverBoxCategory1 qui */}
      <CoverBoxCategory1 />

      {/* Aggiungi CoverBoxBrand1 qui */}
      <CoverBoxBrand1 />

      <div className="product-carousel-container">
        <ProductCarousel />
      </div>

      {/* Aggiungi CoverBoxImage2 qui */}
      <CoverBoxImage2 />

      {/* Add the Newsletter component */}
      <Newsletter />
    </div>
  );
};

export default Home;




