// App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import HeaderDesktop from './Components/HeaderDesktop/HeaderDesktop'; // Importa HeaderDesktop
import HeaderMobile from './Components/HeaderMobile/HeaderMobile'; // Importa HeaderMobile
import MegaMenu from './Components/MegaMenu/MegaMenu';
import Footer from './Components/Footer/Footer';
import FooterBottom from './Components/FooterBottom/FooterBottom';
import Home from './pages/Home/Home';
import Register from './Components/Auth/Register/Register';
import PasswordRecover from './pages/PasswordRecover/PasswordRecover'; // Importa PasswordRecover
import Account from './pages/Account/Account';
import LoginPage from './pages/LoginPage/LoginPage';
import CategoryPage from './pages/CategoryPage/CategoryPage';
import SubcategoryPage from './pages/CategoryPage/SubcategoryPage';
import ProductPage from './pages/ProductPage/ProductPage';
import './App.css';

const App = () => {
  const [isMegaMenuVisible, setMegaMenuVisibility] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const toggleMegaMenu = () => {
    setMegaMenuVisibility(!isMegaMenuVisible);
  };

  const handleSearch = () => {
    console.log('Search icon clicked!');
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Aggiungi un listener per l'evento di ridimensionamento della finestra
    window.addEventListener('resize', handleResize);

    // Pulisci il listener quando il componente si smonta
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // L'effetto viene eseguito solo al mount del componente

  return (
    <Router>
      <div className="app">
        <Helmet>
          <title>Abbigliamento Donna</title>
        </Helmet>

        {/* Utilizza HeaderDesktop solo per dispositivi di grandi dimensioni */}
        {windowWidth > 1080 && <HeaderDesktop />}

        {/* Utilizza HeaderMobile in base alle dimensioni dello schermo */}
        {windowWidth <= 768 && <HeaderMobile onSearch={handleSearch} />}

        {isMegaMenuVisible && <MegaMenu />}

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/account" element={<Account isAuthenticated={true /* Passa il valore di autenticazione */} />} />
          <Route path="/register" element={<Register />} />
          <Route path="/password-recover" element={<PasswordRecover />} />
          <Route path="/login" element={<LoginPage />} /> {/* Aggiungi questa linea per la pagina di accesso */}
          <Route path="/:category" element={<CategoryPage />} />
          <Route path="/:category/:subcategorySlug" element={<SubcategoryPage />} />
          <Route path="/products" element={<ProductPage />} />
        </Routes>

        <Footer />
        <FooterBottom />
      </div>
    </Router>
  );
};

export default App;



