// src/Components/Breadcrumb/Breadcrumb.js
import React from 'react';
import { Link } from 'react-router-dom';
import './Breadcrumb.css';

const Breadcrumb = ({ items }) => {
  return (
    <div className="breadcrumb-container">
      <h1 className="breadcrumb">
        {items.map((item, index, array) => (
          <React.Fragment key={index}>
            <Link to={item.path}>{item.name}</Link>
            {index < array.length - 1 && <span>&nbsp;&gt;&nbsp;</span>}
          </React.Fragment>
        ))}
      </h1>
    </div>
  );
};

export default Breadcrumb;
