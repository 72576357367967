import React, { useRef, useState, useEffect } from 'react';
import './SubMenuDonna.css';

const SubMenuDonna = ({ onClose }) => {
  const submenuRef = useRef(null);
  const [isSubMenuOpen, setSubMenuOpen] = useState(false);

  useEffect(() => {
    if (isSubMenuOpen) {
      submenuRef.current.classList.add('open');
    } else {
      submenuRef.current.classList.remove('open');
    }
  }, [isSubMenuOpen]);

  const handleSubMenuLeave = () => {
    onClose();
  };

  return (
    <div
      className="submenu"
      ref={submenuRef}
      onMouseLeave={handleSubMenuLeave}
    >
      <div className="submenu-container">
        <div className="submenu-column">
          <h3>CATEGORIE</h3>
          <a href="/abbigliamento" className="nav-link">Abbigliamento</a>
          <a href="/accessori" className="nav-link">Accessori</a>
          <a href="/borse" className="nav-link">Borse</a>
          <a href="/gioielli" className="nav-link">Gioielli</a>
          <a href="/orologi" className="nav-link">Orologi</a>
          <a href="/scarpe" className="nav-link">Scarpe</a>
        </div>

        <div className="submenu-column">
          <h3>TOP BRAND</h3>
          <a href="/armani" className="nav-link">Armani</a>
          <a href="/adidas" className="nav-link">Adidas</a>
          <a href="/versace" className="nav-link">Versace</a>
          <a href="/puma" className="nav-link">Puma</a>
          <a href="/amazon" className="nav-link">Amazon</a>
          <a href="/nike" className="nav-link">Nike</a>
        </div>

        <div className="submenu-column">
          <h3>IN EVIDENZA</h3>
          <a href="/armani" className="nav-link">Armani</a>
          <a href="/adidas" className="nav-link">Adidas</a>
          <a href="/versace" className="nav-link">Versace</a>
          <a href="/puma" className="nav-link">Puma</a>
          <a href="/amazon" className="nav-link">Amazon</a>
          <a href="/gucci" className="nav-link">Gucci</a>
        </div>

        <div className="submenu-highlight">
          <img src="slider1.jpg" alt="Offerte speciali" />
        </div>
      </div>
    </div>
  );
};

export default SubMenuDonna;






