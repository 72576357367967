// src/Components/Filters/Filters.js
import React, { useState } from 'react';
import './Filters.css';
import CategoryFilter from './CategoryFilter/CategoryFilter';

const Filters = ({ categories, filters = {}, onChange }) => {
  const [selectedCategory, setSelectedCategory] = useState(filters.category || '');

  const handleFilterChange = (filterKey, value) => {
    if (filterKey === 'category') {
      setSelectedCategory(value);
    }
    onChange(filterKey, value);
  };

  return (
    <div className="filters">

      {/* Filtro Categoria */}
      <CategoryFilter
        categories={categories || []}
        selectedCategory={selectedCategory}
        onCategoryChange={(category) => handleFilterChange('category', category)}
      />

      {/* ... altri filtri ... */}
    </div>
  );
};

export default Filters;



