// data/Products/Products.js
const products = [
  {
    id: 1,
    name: 'Nike Dri-Fit Academy Polo Donna',
    price: '99.99 €',
    discount: 20,
    image: 'https://styleoutfit.it/wp-content/uploads/2023/11/nikedri-fitacademypolodonnaroyalblubiancoossidianabiancos.jpg',
    url: 'https://styleoutfit.it/',
    size: 'M',
    color: 'Royal Blu/Bianco/Ossidiana',
    brand: 'Nike',
    store: 'Style Outfit',
    categories: [1, 4, 6], // Donna > Abbigliamento > Maglie
  },
  {
    id: 2,
    name: 'Oodlo Donna Maglia Funzionale a Maniche Lunghe con Collo Alto Active Warm Eco',
    price: '39.99 €',
    discount: 10,
    image: 'https://styleoutfit.it/wp-content/uploads/2023/11/odlodonnamagliafunzionaleamanichelungheconcolloaltoactivewarmeco.jpg',
    url: 'https://styleoutfit.it/',
    size: 'S',
    color: 'Varie',
    brand: 'Oodlo',
    store: 'Style Outfit',
    categories: [1, 4, 6], // Donna > Abbigliamento > Maglie
  },
  {
    id: 3,
    name: 'Icyzone Reggiseno Sportivo Donna Imbottito Senza Ferretto Disegno della Linea',
    price: '59.99 €',
    discount: 15,
    image: 'https://styleoutfit.it/wp-content/uploads/2023/11/icyzonereggisenosportivodonnaimbottitosenzaferrettodisegnodellalinea-3.jpg',
    url: 'https://styleoutfit.it/',
    size: 'Varie',
    color: 'Varie',
    brand: 'Icyzone',
    store: 'Style Outfit',
    categories: [1, 4, 7], // Donna > Abbigliamento > Intimo
  },
  {
    id: 4,
    name: 'Under Armour HG Armour Mid Padless Reggiseno Bianco Donna',
    price: '149.99 €',
    discount: 25,
    image: 'https://styleoutfit.it/wp-content/uploads/2023/11/underarmourhgarmourmidpadlessreggisenobiancosdonna.jpg',
    url: 'https://styleoutfit.it/',
    size: 'Varie',
    color: 'Bianco',
    brand: 'Under Armour',
    store: 'Style Outfit',
    categories: [1, 4, 7], // Donna > Abbigliamento > Intimo
  },
  {
    id: 5,
    name: 'Under Armour Donna Rival Fleece Joggers Pantaloni Tuta Donna Morbidi',
    price: '19.99 €',
    discount: 5,
    image: 'https://styleoutfit.it/wp-content/uploads/2023/11/underarmourdonnarivalfleecejoggerspantalonitutadonnamorbidi.jpg',
    url: 'https://styleoutfit.it/',
    size: 'Varie',
    color: 'Varie',
    brand: 'Under Armour',
    store: 'Style Outfit',
    categories: [1, 4, 8], // Donna > Abbigliamento > Pantaloni
  },
  {
    id: 6,
    name: 'Adidas Ultraboost 21 Scarpe Running Donna',
    price: '129.99 €',
    discount: 15,
    image: 'https://styleoutfit.it/wp-content/uploads/2023/11/icepeakflemintonshirtbambinaroyalblues.jpg',
    url: 'https://styleoutfit.it/',
    size: '38-40',
    color: 'Bianco',
    brand: 'Adidas',
    store: 'Style Outfit',
    categories: [1, 5, 9], // Donna > Scarpe > Running
  },
  {
    id: 7,
    name: 'Puma Cali Sneakers Alte Donna',
    price: '89.99 €',
    discount: 10,
    image: 'https://styleoutfit.it/wp-content/uploads/2023/11/icepeakflemintonshirtbambinaroyalblues.jpg',
    url: 'https://styleoutfit.it/',
    size: '36-38',
    color: 'Nero',
    brand: 'Puma',
    store: 'Style Outfit',
    categories: [1, 5, 10], // Donna > Scarpe > Sneakers
  },
  {
    id: 8,
    name: 'Gucci GG Marmont Borsa a Tracolla Mini',
    price: '1390.00 €',
    discount: 0,
    image: 'https://styleoutfit.it/wp-content/uploads/2023/11/icepeakflemintonshirtbambinaroyalblues.jpg',
    url: 'https://styleoutfit.it/',
    size: 'Mini',
    color: 'Rosso',
    brand: 'Gucci',
    store: 'Style Outfit',
    categories: [1, 3, 11], // Donna > Accessori > Borse
  },
  {
    id: 9,
    name: 'Versace Medusa Collana con Pendente',
    price: '330.00 €',
    discount: 10,
    image: 'https://styleoutfit.it/wp-content/uploads/2023/11/icepeakflemintonshirtbambinaroyalblues.jpg',
    url: 'https://styleoutfit.it/',
    size: 'Varie',
    color: 'Oro',
    brand: 'Versace',
    store: 'Style Outfit',
    categories: [1, 3, 12], // Donna > Accessori > Gioielli
  },
  {
    id: 10,
    name: 'Dior Book Tote Borsa a Mano Grande',
    price: '2450.00 €',
    discount: 0,
    image: 'https://styleoutfit.it/wp-content/uploads/2023/11/icepeakflemintonshirtbambinaroyalblues.jpg',
    url: 'https://styleoutfit.it/',
    size: 'Grande',
    color: 'Bianco',
    brand: 'Dior',
    store: 'Style Outfit',
    categories: [1, 3, 11], // Donna > Accessori > Borse
  },
  // Aggiungi altri 10 prodotti qui
  // ...
];

export default products;
