// src/Components/SearchBarMobile/SearchBarMobile.js
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import './SearchBarMobile.css';

const SearchBarMobile = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]); // Stato per memorizzare i risultati della ricerca

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearch = () => {
    // Qui puoi implementare la logica di ricerca
    // Ad esempio, puoi fare una richiesta API per cercare i risultati
    // In questo esempio, sto solo stampando i risultati in console
    console.log(`Hai cercato: ${searchTerm}`);
    
    // Dopo aver ottenuto i risultati, puoi aggiornare lo stato 'searchResults'
    // setSearchResults(risultatiDellaRicerca);
  };

  const handleReset = () => {
    setSearchTerm('');
    setSearchResults([]); // Pulisci anche i risultati quando si fa il reset
  };

  return (
    <div className="search-bar-mobile-container">
      <div className="search-box-mobile">
        <input
          type="text"
          placeholder="Cosa stai cercando..."
          value={searchTerm}
          onChange={handleInputChange}
          className="search-input-mobile"
        />
        {searchTerm && (
          <button className="reset-button-mobile" onClick={handleReset}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        )}
        <button className="search-button-mobile" onClick={handleSearch}>
          <FontAwesomeIcon icon={faSearch} />
        </button>
      </div>
      {/* Aggiungi qui la visualizzazione dei risultati di ricerca se necessario */}
    </div>
  );
};

export default SearchBarMobile;

