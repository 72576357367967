// src/Components/CoverBoxBrand/CoverBoxBrand1.js
import React from 'react';
import './CoverBoxBrand1.css'; // Assicurati di avere il percorso corretto

const CoverBoxBrand1 = () => {
  return (
    <div className="cover-box-brand-container">
      {/* Brand 1 */}
      <div className="brand-item">
        <a href="liujo" target="_blank" rel="noopener noreferrer">
          <img src={process.env.PUBLIC_URL + '/LogoBrand/LiuJo.png'} alt="liujo" />
        </a>
      </div>

      {/* Brand 2 */}
      <div className="brand-item">
      <a href="liujo" target="_blank" rel="noopener noreferrer">
          <img src={process.env.PUBLIC_URL + '/LogoBrand/Puma.png'} alt="Puma" />
        </a>
      </div>

      {/* Brand 3 */}
      <div className="brand-item">
      <a href="levis" target="_blank" rel="noopener noreferrer">
          <img src={process.env.PUBLIC_URL + '/LogoBrand/Levis.png'} alt="Levis" />
        </a>
      </div>

      {/* Brand 4 */}
      <div className="brand-item">
      <a href="liujo" target="_blank" rel="noopener noreferrer">
          <img src={process.env.PUBLIC_URL + '/LogoBrand/LiuJo.png'} alt="liujo" />
        </a>
      </div>

      {/* Brand 5 */}
      <div className="brand-item">
      <a href="liujo" target="_blank" rel="noopener noreferrer">
          <img src={process.env.PUBLIC_URL + '/LogoBrand/LiuJo.png'} alt="liujo" />
        </a>
      </div>

      {/* Brand 6 */}
      <div className="brand-item">
      <a href="liujo" target="_blank" rel="noopener noreferrer">
          <img src={process.env.PUBLIC_URL + '/LogoBrand/LiuJo.png'} alt="liujo" />
        </a>
      </div>
    </div>
  );
};

export default CoverBoxBrand1;
