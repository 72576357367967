import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../Logo/Logo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faBars } from '@fortawesome/free-solid-svg-icons';
import NavBarMobile from '../NavBarMobile/NavBarMobile';
import SearchBarMobile from '../SearchBarMobile/SearchBarMobile'; // Importa SearchBarMobile
import './HeaderMobile.css';

const HeaderMobile = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const headerRef = useRef(null);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeNavBarMobile = () => {
    setMenuOpen(false);
  };

  return (
    <div>
      <div className="mobile-header" ref={headerRef}>
        <div className="header-left">
          <FontAwesomeIcon
            icon={faBars}
            className="hamburger-icon"
            onClick={(e) => {
              toggleMenu();
              e.stopPropagation();
            }}
          />
        </div>
        <div className="logo-container">
          <Logo />
        </div>
        <Link to="/account" className="account-icon" onClick={closeNavBarMobile}>
          <FontAwesomeIcon icon={faUser} />
          <span className="account-text">Accedi</span>
        </Link>
      </div>
      {menuOpen && <NavBarMobile onMenuToggle={toggleMenu} onClose={closeNavBarMobile} />}
      <SearchBarMobile /> {/* Aggiungi la barra di ricerca sotto l'Header */}
    </div>
  );
};

export default HeaderMobile;

