// data/Categories/Categories.js
const categories = [
    { id: 1, name: 'Donna', slug: 'donna', parent: null, image: 'slider1.jpg' },
    { id: 2, name: 'Uomo', slug: 'uomo', parent: null, image: 'slider1.jpg' },
    { id: 3, name: 'Accessori', slug: 'accessori', parent: null, image: 'slider1.jpg' },
    { id: 4, name: 'Abbigliamento', slug: 'abbigliamento', parent: 1, image: 'slider1.jpg' },
    { id: 5, name: 'Scarpe', slug: 'scarpe', parent: 1, image: 'slider1.jpg' },
    { id: 6, name: 'Maglie', slug: 'maglie', parent: 4, image: 'slider1.jpg' },
    { id: 7, name: 'Intimo', slug: 'intimo', parent: 4, image: 'slider1.jpg' },
    { id: 8, name: 'Pantaloni', slug: 'pantaloni', parent: 4, image: 'slider1.jpg' },
    { id: 9, name: 'Running', slug: 'running', parent: 5, image: 'slider1.jpg' },
    { id: 10, name: 'Sneakers', slug: 'sneakers', parent: 5, image: 'slider1.jpg' },
    { id: 11, name: 'Borse', slug: 'borse', parent: 3, image: 'slider1.jpg' },
    { id: 12, name: 'Gioielli', slug: 'gioielli', parent: 11, image: 'slider1.jpg' },
    { id: 13, name: 'Jeans', slug: 'jeans', parent: 8, image: 'slider1.jpg' },
    // Altri livelli della gerarchia possono essere aggiunti qui
    // ...
  ];
  
  export default categories;
  