import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes, faAngleRight, faAngleLeft, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import './NavBarMobile.css';

const NavBarMobile = ({ onMenuToggle, onClose }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [activeCategory, setActiveCategory] = useState('');
  const [activeSection, setActiveSection] = useState('');

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    onMenuToggle();
  };

  const toggleCategory = (category) => {
    setActiveCategory(activeCategory === category ? '' : category);
    setActiveSection('');
  };

  const toggleSection = (section) => {
    setActiveSection(activeSection === section ? '' : section);
  };

  const handleMenuItemClick = () => {
    onClose();
  };

  const generateCategoryLinks = (category) => {
    let categoryLinks = [];
    switch (category) {
      case 'donna':
        categoryLinks = [
          'Categoria Donna 1',
          'Categoria Donna 2',
          'Categoria Donna 3',
        ];
        break;
      case 'uomo':
        categoryLinks = [
          'Categoria Uomo 1',
          'Categoria Uomo 2',
          'Categoria Uomo 3',
        ];
        break;
      case 'bambina':
        categoryLinks = [
          'Categoria Bambina 1',
          'Categoria Bambina 2',
          'Categoria Bambina 3',
        ];
        break;
      case 'bambino':
        categoryLinks = [
          'Categoria Bambino 1',
          'Categoria Bambino 2',
          'Categoria Bambino 3',
        ];
        break;
      default:
        break;
    }

    return categoryLinks.map((link, index) => (
      <li key={index}>
        <Link
          to={`/${category}/${link.toLowerCase().replace(/\s+/g, '-')}`}
          className="a-link"
          onClick={handleMenuItemClick}
        >
          {link}
        </Link>
      </li>
    ));
  };

  const generateTopBrandLinks = (category) => {
    let topBrandLinks = [];
    switch (category) {
      case 'donna':
        topBrandLinks = [
          'Top Brand Donna 1',
          'Top Brand Donna 2',
          'Top Brand Donna 3',
        ];
        break;
      case 'uomo':
        topBrandLinks = [
          'Top Brand Uomo 1',
          'Top Brand Uomo 2',
          'Top Brand Uomo 3',
        ];
        break;
      case 'bambina':
        topBrandLinks = [
          'Top Brand Bambina 1',
          'Top Brand Bambina 2',
          'Top Brand Bambina 3',
        ];
        break;
      case 'bambino':
        topBrandLinks = [
          'Top Brand Bambino 1',
          'Top Brand Bambino 2',
          'Top Brand Bambino 3',
        ];
        break;
      default:
        break;
    }

    return topBrandLinks.map((brand, index) => (
      <li key={index}>
        <Link
          to={`/${category}/top-brand/${brand.toLowerCase().replace(/\s+/g, '-')}`}
          className="a-link"
          onClick={handleMenuItemClick}
        >
          {brand}
        </Link>
      </li>
    ));
  };

  const generateInEvidenzaLinks = (category) => {
    let inEvidenzaLinks = [];
    switch (category) {
      case 'donna':
        inEvidenzaLinks = [
          'Borse a Tracolla',
          'Borse a Mano',
          'Zaini',
          'Pochette',
          'Clutch',
          'Nulla',
        ];
        break;
      case 'uomo':
        inEvidenzaLinks = [
          'Altri Uomini',
          'Scarpe Uomo',
          'Occhiali Uomo',
        ];
        break;
      case 'bambina':
        inEvidenzaLinks = [
          'Giochi Bambina',
          'Vestiti Bambina',
          'Scarpe Bambina',
        ];
        break;
      case 'bambino':
        inEvidenzaLinks = [
          'Giochi Bambino',
          'Vestiti Bambino',
          'Scarpe Bambino',
        ];
        break;
      default:
        break;
    }
  
    return inEvidenzaLinks
      .filter((item) => item !== 'Nulla') // Rimuovi l'elemento 'Nulla'
      .map((item, index) => (
        <li key={index}>
          <Link
            to={`/${category}/${item.toLowerCase().replace(/\s+/g, '-')}`}
            className="a-link"
            onClick={handleMenuItemClick}
          >
            {item}
          </Link>
        </li>
      ));
  };
  
  
  const mainMenu = [
    { label: 'Donna', key: 'donna' },
    { label: 'Uomo', key: 'uomo' },
    { label: 'Bambina', key: 'bambina' },
    { label: 'Bambino', key: 'bambino' },
  ];

  const subMenuItems = (
    <ul className="categories">
      <li className="submenu-back" onClick={() => toggleCategory('')}>
        <FontAwesomeIcon icon={faAngleLeft} />
        Indietro
      </li>
      <hr />
      <h3>CATEGORIE</h3>
      {generateCategoryLinks(activeCategory)}
      <hr />
      <h3>TOP BRAND</h3>
      {generateTopBrandLinks(activeCategory)} {/* Mostra i link dei Top Brand */}
      <hr />
      <h3>IN EVIDENZA</h3>
      {generateInEvidenzaLinks(activeCategory)} {/* Mostra le categorie IN EVIDENZA */}
    </ul>
  );

  const menuItems = activeCategory
    ? subMenuItems
    : (
      <ul className="categories">
        {mainMenu.map((category, index) => (
          <li key={index} onClick={() => toggleCategory(category.key)}>
            {category.label}
            <FontAwesomeIcon
              icon={activeCategory === category.key ? faAngleDown : faAngleRight}
            />
          </li>
        ))}
      </ul>
    );

    return (
      <div className={`navbar-mobile ${menuOpen ? 'open' : ''}`}>
        <div className="navbar-header">
        <div className="menu-label">MENU</div>
          <div className="menu-toggle" onClick={toggleMenu}>
            <FontAwesomeIcon icon={menuOpen ? faTimes : faTimes} /> {/* Usa l'icona "X" sempre */}
        </div>
      </div>
      <div className="navbar-content">
        {menuItems}
      </div>
    </div>
  );
};

export default NavBarMobile;
