// src/Components/SearchBarDesktop/SearchBarDesktop.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import './SearchBarDesktop.css';

const SearchBarDesktop = () => {
  return (
    <form className="search-bar-desktop">
      <div className="search-input-desktop">
        <input type="text-desktop" placeholder="Cosa stai cercando..." className="search-input-desktop" />
        <button type="submit-desktop" className="search-button-desktop">
          <FontAwesomeIcon icon={faSearch} />
        </button>
        <button type="reset-desktop" className="reset-button-desktop">
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>
    </form>
  );
}

export default SearchBarDesktop;
