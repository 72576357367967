// src/components/Auth/Register/Register.js
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom'; // Importa useNavigate da 'react-router-dom'
import './Register.css';

const Register = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    gender: '',
  });

  const navigate = useNavigate(); // Crea una variabile navigate per gestire la navigazione

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Qui puoi aggiungere la logica di registrazione, ad esempio una chiamata API
    console.log(formData);

    // Dopo la registrazione, esegui la navigazione alla pagina Account.js
    navigate('/account'); // Assicurati che '/account' sia il percorso corretto per la pagina Account
  };

  return (
    <div className="register-container">
      <Helmet>
        <title>Registrazione - Titolo SEO Personalizzato</title>
      </Helmet>

      <h2>Registrazione</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Email</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Password</label>
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Conferma Password</label>
          <input
            type="password"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Sesso</label>
          <select
            className="select-field"
            name="gender"
            value={formData.gender}
            onChange={handleChange}
            required
          >
            <option value="">Seleziona</option>
            <option value="male">Maschio</option>
            <option value="female">Femmina</option>
          </select>
        </div>
        <button type="submit">Registrati</button>
      </form>
    </div>
  );
};

export default Register;

