// src/Components/SingleProduct/SingleProduct.js
import React from 'react';
import './SingleProduct.css';

const SingleProduct = ({ product }) => {
  const { name, price, originalPrice, discountedPrice, image, discountLabel, wishlistIcon } = product;

  return (
    <div className="product-card">
      <div className="product-image-container">
        <img src={image} alt={name} className="product-image" />
        {discountLabel && <div className="discount-label">{discountLabel}</div>}
        {wishlistIcon && <div className="wishlist-icon">{wishlistIcon}</div>}
      </div>
      <div className="product-details">
        <div className="product-name">{name}</div>
        <div className="product-price">
          {discountedPrice && (
            <span className="discounted-price">{discountedPrice}</span>
          )}
          {originalPrice && (
            <span className="original-price">{originalPrice}</span>
          )}
          {price}
        </div>
      </div>
    </div>
  );
};

export default SingleProduct;

