// src/pages/LoginPage/LoginPage.js
import React from 'react';
import './LoginPage.css';
import { Helmet } from 'react-helmet';
import Login from '../../Components/Auth/Login/Login'; // Importa il componente Login

const LoginPage = () => {
  return (
    <div className="login-page-container">
      {/* Use Helmet to set the title dynamically */}
      <Helmet>
        <title>Login Page</title>
      </Helmet>
      {/* Utilizza CSS per nascondere il titolo visivamente */}
      <h1 className="visually-hidden">Benvenuto nella pagina di accesso</h1>
      {/* Utilizza il componente Login */}
      <Login />
    </div>
  );
};

export default LoginPage;





