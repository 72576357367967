// Footer.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebook,
  faInstagram,
  faTwitter,
  faPinterest,
} from '@fortawesome/free-brands-svg-icons';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-columns">
        <div className="footer-column">
          <h4>STYLEOUTFIT</h4>
          <ul>
            <li><a href="/">Link 1</a></li>
            <li><a href="/">Link 2</a></li>
            <li><a href="/">Link 3</a></li>
            <li><a href="/">Link 1</a></li>
          </ul>
        </div>

        <div className="footer-column">
          <h4>LINK UTILI</h4>
          <ul>
            <li><a href="/">Link 4</a></li>
            <li><a href="/">Link 5</a></li>
            <li><a href="/">Link 6</a></li>
            <li><a href="/">Link 1</a></li>
          </ul>
        </div>

        <div className="footer-column">
          <h4>INFORMATIVE</h4>
          <ul>
            <li><a href="/">Link 7</a></li>
            <li><a href="/">Link 8</a></li>
            <li><a href="/">Link 9</a></li>
            <li><a href="/">Link 1</a></li>
          </ul>
        </div>

        <div className="footer-column">
          <h4>SEGUICI SU</h4>
          <ul className="social-icons-column">
            <li>
              <a href="https://www.facebook.com/">
                <FontAwesomeIcon icon={faFacebook} />
                <span>Facebook</span>
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/">
                <FontAwesomeIcon icon={faInstagram} />
                <span>Instagram</span>
              </a>
            </li>
            <li>
              <a href="https://twitter.com/">
                <FontAwesomeIcon icon={faTwitter} />
                <span>Twitter</span>
              </a>
            </li>
            <li>
              <a href="https://www.pinterest.com/">
                <FontAwesomeIcon icon={faPinterest} />
                <span>Pinterest</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
