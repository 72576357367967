// src/Components/CoverBoxImage2/CoverBoxImage2.js

import React from 'react';
import './CoverBoxImage2.css'; // Assicurati che il percorso sia corretto

const CoverBoxImage1 = () => {
  return (
    <div className="cover-box-image-1-container">
      {/* Immagine a sinistra */}
      <img src={process.env.PUBLIC_URL + '/Immagini Home/wow.png'} alt="Descrizione" className="cover-box-1-image-left" />

      {/* Testo e pulsante a destra */}
      <div className="text-button-cover-box-image-1-container">
        <h2>Buon shopping natalizio, sereno e tranquillo</h2>
        <p>Gestisci lo stress natalizio con le nostre idee regalo.</p>
        <button>Scopri di più</button>
      </div>
    </div>
  );
};

export default CoverBoxImage1;

