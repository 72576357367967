// src/Components/NavBarDesktop/NavBarDesktop.js
import React, { useState } from 'react';
import './NavBarDesktop.css';
import SubMenuDonna from '../SubMenuDonna/SubMenuDonna';

function NavBarDesktop() {
  const [isSubMenuOpen, setSubMenuOpen] = useState(false);

  const handleMouseEnter = () => {
    setSubMenuOpen(true);
  };

  const handleCloseSubMenu = () => {
    setSubMenuOpen(false);
  };

  return (
    <nav className="navbar-desktop">
      <ul className="nav-list-desktop">
        <li
          className="nav-item-desktop"
          onMouseEnter={handleMouseEnter}
        >
          <a href="/donna">Donna</a>
        </li>
        <li className="nav-item-desktop">
          <a href="/uomo">Uomo</a>
        </li>
        <li className="nav-item-desktop">
          <a href="/bambina">Bambina</a>
        </li>
        <li className="nav-item-desktop">
          <a href="/bambino">Bambino</a>
        </li>
        <li className="nav-item-desktop">
          <a href="/magazine">Magazine</a>
        </li>
      </ul>
      {isSubMenuOpen && <SubMenuDonna onClose={handleCloseSubMenu} />}
    </nav>
  );
}

export default NavBarDesktop;


















