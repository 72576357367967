// src/Components/CoverBoxCategory1/CoverBoxCategory1.js
import React from 'react';
import './CoverBoxCategory1.css'; // Assicurati di avere il percorso corretto

const CoverBoxCategory1 = () => {
  return (
    <div className="cover-box-category-container">
      {/* Prima immagine */}
      <a href="/donna" className="cover-box-category-1-item">
        <img src={process.env.PUBLIC_URL + '/slider1.jpg'} alt="Donna" />
        <p>Donna</p>
      </a>

      {/* Seconda immagine */}
      <a href="/uomo" className="cover-box-category-1-item">
        <img src={process.env.PUBLIC_URL + '/slider1.jpg'} alt="Uomo" />
        <p>Uomo</p>
      </a>

      {/* Terza immagine */}
      <a href="/bambine-e-ragazze" className="cover-box-category-1-item">
        <img src={process.env.PUBLIC_URL + '/slider1.jpg'} alt="Bambine e Ragazze" />
        <p>Bambine e Ragazze</p>
      </a>

      {/* Quarta immagine */}
      <a href="/bambini-e-ragazzi/" className="cover-box-category-1-item">
        <img src={process.env.PUBLIC_URL + '/slider1.jpg'} alt="Bambini e Ragazzi" />
        <p>Bambini e Ragazzi</p>
      </a>
    </div>
  );
};

export default CoverBoxCategory1;

