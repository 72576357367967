// src/pages/ProductPage.js
import React from 'react';
import { connect } from 'react-redux';
import CategoryFilter from '../../Components/Filters/CategoryFilter/CategoryFilter'; // Correggi il percorso
import ProductList from '../../Components/ProductList/ProductList'; // Correggi il percorso

const ProductPage = ({ productCategories, selectedCategory, handleCategoryChange }) => {
  return (
    <div>
      <CategoryFilter
        categories={productCategories} // Cambiato il nome della prop per essere più espliciti
        selectedCategory={selectedCategory}
        onCategoryChange={handleCategoryChange}
      />
      <ProductList selectedCategory={selectedCategory} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  productCategories: state.productCategories,
  selectedCategory: state.selectedCategory,
  // ... altre props dallo stato
});

const mapDispatchToProps = (dispatch) => ({
  handleCategoryChange: (category) => {
    // Azioni Redux per gestire il cambio di categoria
    dispatch({ type: 'CHANGE_CATEGORY', payload: category });
  },
  // ... altre azioni se necessario
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductPage);
